import React from "react";
import { LangContext } from "../../context/LangContext";
import { Link } from "react-router-dom";
import "./footer.scss";

const Footer = () => {
  const lang = React.useContext(LangContext);
  const np = lang.state.np;
  return (
    <div className="footerSectionBGContainer">
      <div className="footerSectionMainContainer">
        <div className="allListContainer">
          <div className="singleListCont">
            <h4>{np ? "सम्पर्कमा रहनुहोस्" : "Get In Touch"}</h4>
            <div className="flexCont">
              <p>
                {np
                  ? "ठेगाना: थिर्बम साधक-२, भाटभटेनी"
                  : "Address: Thirbam Sadhak-2, Bhatbhateni"}
              </p>
              <p>{np ? "फोन: ०१-४५२५२९२" : "Phone: 01-4525292"}</p>
              {/* <a href="#">
                {np
                  ? "गुनासो अधिकारीको नम्बर : 9851053756"
                  : "Grievance Officer's No. : 9851053756"}
              </a>
              <a href="#">
                {np
                  ? "सूचना अधिकारीको नम्बर : 9851107577"
                  : "Information Officer's No. : 9851107577"}
              </a> */}
              <p>{np ? "हुलाक कोड: 25460" : "Postal Code: 25460"}</p>
              <a href="mailto:info@icfc.com.np">
                {np ? "इमेल: info@icfc.com.np" : "Email: info@icfc.com.np"}
              </a>
            </div>
          </div>

          <div className="singleListCont">
            <h4>{np ? "हाम्रो बारे" : "About Us"}</h4>
            <div className="flexCont">
              <Link to="about-us/company-profile">
                {np ? "मिशन, भिजन र मूल्यहरू" : "Mission, Vision & Values"}
              </Link>
              <Link to="about-us/ceo-message">
                {np ? "प्रमुख कार्यकारी अधिकृतको सन्देश" : "CEO's Message"}
              </Link>
              <Link to="teams/bod">
                {np ? "सञ्चालक समिति" : "Board of Directors"}
              </Link>
              <Link to="teams/management-team">
                {np ? "व्यवस्थापन समूह" : "Management Team"}
              </Link>
              <Link to="teams/hod">
                {np ? "विभागीय प्रमुख" : "Head of Department"}
              </Link>
              {/* <Link to="teams/regional-head">
                {np ? "प्रदेश प्रमुखहरु" : "Province Heads"}
              </Link> */}
            </div>
          </div>

          <div className="singleListCont">
            <h4>{np ? "द्रुत लिङ्कहरू" : "Quick Links"}</h4>
            <div className="flexCont">
              <Link to="/interest-rates">
                {np ? "ब्याज दरहरू" : "Interest Rates"}
              </Link>
              <Link to="/fiscal-year-interest-rates">
                {np
                  ? "आर्थिक वर्ष अनुसार ब्याज दर"
                  : "Interest Rates Fiscal Year"}
              </Link>
              <Link to="/product">{np ? "उत्पादनहरु" : "Products"}</Link>
              {/* <Link to="/services/icfc-ebanking">
                {np ? "सेवाहरू" : "Services"}
              </Link> */}
              <Link to="/forex">{np ? "विदेशी मुद्रा दर" : "Forex Rates"}</Link>
              <Link
                to="https://admin.icfcbank.com/uploads/Final_TARRIF.pdf"
                target="_blank"
              >
                {np ? "मानक ट्यारिफ शुल्कहरू" : "Standard Tariff Charges"}
              </Link>
              <Link to="/learning_and_development">
                {np ? "सिकाइ र विकास" : "Learning and Development"}
              </Link>
              <Link to="/notices">{np ? "सूचनाहरू" : "Notices"}</Link>
              <Link
                to="https://admin.icfcbank.com/uploads/Transaction_limit/Transaction_limit_revision.pdf"
                target="_blank"
              >
                {np ? "लेनदेन सीमा" : "Transaction limit"}
              </Link>
            </div>
          </div>

          <div className="singleListCont">
            <h4>{np ? "समर्थन" : "Support"}</h4>
            <div className="flexCont">
              <Link to="/contact-us">
                {np ? "सम्पर्क गर्नुहोस" : "Contact Us"}
              </Link>
              {/* <Link to="">Grievance Handling</Link> */}
              <Link to="/downloads">{np ? "डाउनलोडहरू" : "Downloads"}</Link>
              <Link to="/branch-location">
                {np ? "शाखा स्थान" : "Branch Location"}
              </Link>
              <Link to="/atm-location">
                {np ? "एटीएम स्थानहरु" : "ATM Location"}
              </Link>
              <Link to="/faqs">
                {np ? "कसरी" : "Frequently Asked Questions"}
              </Link>

              {/* <Link to="">Privacy Statement</Link> */}
            </div>
          </div>
        </div>
        <div className="copySectionContainer">
          {np ? (
            <p>
              प्रतिलिपि अधिकार © {new Date().getFullYear()} ICFC फाइनान्स
              लिमिटेड। सबै अधिकार सुरक्षित।
            </p>
          ) : (
            <p>
              Copyright © {new Date().getFullYear()} ICFC Finance Limited. All
              Rights Reserved.
            </p>
          )}

          {np ? (
            <p>
              विकसित द्वारा:
              <a
                className="redirect"
                href="https://nyatapol.com/"
                target="_blank"
              >
                न्याटापोल प्रविधि
              </a>
            </p>
          ) : (
            <p>
              Developed by:
              <a
                className="redirect"
                href="https://nyatapol.com/"
                target="_blank"
              >
                Nyatapol Technologies
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
