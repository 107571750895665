import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import "./navbar.scss";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { Link, useNavigate } from "react-router-dom";
import login from "../../assets/navbar/login.png";
// import search from '../../assets/navbar/search.png'
import logo from "../../assets/navbar/logo.png";
import mustcote from "../../assets/navbar/mustcote.png";
import logoFull from "../../assets/logoFull.jpg";
// import NepaliDate from "nepali-date";
import NepaliDate from "nepali-date-converter";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import img01 from "../../assets/navbar/01.jpg";
import img02 from "../../assets/navbar/02.jpg";
import img03 from "../../assets/navbar/03.jpg";
import img04 from "../../assets/navbar/04.jpg";
import img05 from "../../assets/navbar/05.jpg";
import img06 from "../../assets/navbar/06.jpg";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import {MdOutlineNotificationsNone} from 'react-icons/md';
import { BiSearch } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { LangContext } from "../../context/LangContext";

import { CSSTransition } from "react-transition-group";
// import {AiOutlineCalendar} from 'react-icons/ai'
// import {HiOutlineCalendarDays} from 'react-icons/hi';
// import {IoTodayOutline} from 'react-icons/io'
import { IoIosArrowDown } from "react-icons/io";
import { BiVolumeFull } from "react-icons/bi";
import { BsCalendarWeek } from "react-icons/bs";
import { MdOutlineNotificationsNone } from "react-icons/md";
// import MegaMenuAnimation from '../../utils/MegaMenuAnimation';

import OutsideClickHandler from "react-outside-click-handler";

// import component 👇
import Drawer from "react-modern-drawer";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import AudioNoticeDrop from "../AudioNoticeDrop";
import Notification from "../Notification";
import LoginDropdown from "../LoginDropdown";

import OnlineDropdown from "../onlinedropdown";

const NavData = [
  {
    id: 1,
    name: "About Us",
    slug: "about-us",
    nameNp: "हाम्रो बारे",
    img: "",
    subNavData: [
      {
        name: "Company Profile",
        nameNp: "कम्पनी प्रोफइल",
        link: "about-us/company-profile",
      },
      {
        name: "CEO's Message",
        nameNp: "प्रमुख कार्यकारी अधिकृतको सन्देश",
        link: "about-us/ceo-message",
      },
      {
        name: "Board of Director",
        nameNp: "सञ्चालक समिति",
        link: "teams/bod",
      },
      {
        name: "Management Team",
        nameNp: "व्यवस्थापन समूह",
        link: "teams/management-team",
      },
      {
        name: "Head of Department",
        nameNp: "विभागीय प्रमुख",
        link: "teams/hod",
      },
      {
        name: "Regional Head",
        nameNp: "प्रदेश प्रमुखहरु",
        link: "teams/regional-head",
      },
      {
        name: "Company Secretary",
        nameNp: "कम्पनी सचिव",
        link: "teams/company-secretary",
      },
      {
        name: "Grievance Officer",
        nameNp: "गुनासो अधिकारी",
        link: "officers/officer/amit-shrestha",
      },
      {
        name: "Branchwise Information Officials",
        nameNp: "शाखा अनुसार सूचना अधिकारीहरू",
        link: "branch-officers/information",
      },
      // {
      //   name: "IT Officials",
      //   nameNp: "सूचना अधिकारीहरू",
      //   link: "teams/it-officers",
      // },
      {
        name: "CSR",
        nameNp: "सामाजिक उत्तरदायित्व",
        link: "/csr",
      },
      {
        name: "Notices",
        nameNp: "सूचनाहरू",
        link: "/notices",
      },
      {
        name: "News",
        nameNp: "समाचार",
        link: "/news",
      },
    ],
  },
  {
    id: 2,
    name: "Our Products",
    slug: "our-products",
    nameNp: "हाम्रा सेवाहरू",
    img: "",
    subNavData: [
      {
        name: "ICFC Bumper Saving Account",
        nameNp: "ICFC बम्पर बचत खाता",
        link: "product/saving-account/icfc-bumper-saving-account",
      },
      {
        name: "ICFC Social Security",
        nameNp: "ICFC सामाजिक सुरक्षा",
        link: "product/saving-account/icfc-social-security",
      },
      // {
      //   name: "ICFC Premium Saving",
      //   nameNp: "ICFC प्रिमियम बचत",
      //   link: "product/saving-account/icfc-premium-saving",
      // },
      // {
      //   name: "Saving Account",
      //   nameNp: "बचत खाता",
      //   link: "product/saving-account/saving-account",
      // },
      {
        name: "Employee Account",
        nameNp: "कर्मचारी खाता",
        link: "product/saving-account/employee-account",
      },
      {
        name: "Special Saving",
        nameNp: "विशेष बचत",
        link: "product/saving-account/special-saving",
      },
      // {
      //   name: "Super Saving Account",
      //   nameNp: "सुपर बचत खाता",
      //   link: "product/saving-account/super-saving",
      // },
      {
        name: "ICFC Zero Balance",
        nameNp: "ICFC शून्य ब्यालेन्स",
        link: "product/saving-account/icfc-zero-saving",
      },
      {
        name: "Senior Citizen A/C",
        nameNp: "वरिष्ठ नागरिक खाता",
        link: "product/saving-account/senior-citizen-saving",
      },
      {
        name: "Keta Keti Bachat",
        nameNp: "केटा केटी बचत",
        link: "product/saving-account/ketaketi-bachat",
      },
      {
        name: "Nari Bachat",
        nameNp: "नारी बचत",
        link: "product/saving-account/nari-bachat",
      },
      {
        name: "Yuva Bachat",
        nameNp: "युवा बचत",
        link: "product/saving-account/yuva-bachat",
      },
      // {
      //   name: "Golden Saving",
      //   nameNp: "गोल्डेन बचत",
      //   link: "product/saving-account/golden-saving",
      // },
      {
        name: "Jumbo Saving",
        nameNp: "जम्बो बचत",
        link: "product/saving-account/jumbo-saving",
      },
      {
        name: "ASBA Saving",
        nameNp: "ASBA बचत",
        link: "product/saving-account/asba-saving-account",
      },
      {
        name: "Remittance Saving Account",
        nameNp: "रेमिट्यान्स बचत खाता",
        link: "product/saving-account/remittance-saving-account",
      },
      {
        name: "Recurring Fixed Deposit",
        nameNp: "आवर्ती फिक्स्ड डिपोजिट",
        link: "product/fixed-deposit/recurring-fixed-deposit",
      },
      {
        name: "Fixed Deposit",
        nameNp: "फिक्स्ड डिपोजिट",
        link: "product/fixed-deposit/fixed-deposit",
      },
      {
        name: "SME Loan",
        nameNp: "SME कर्जा",
        link: "product/professional-loan/sme-loan",
      },
      {
        name: "Personal Loan",
        nameNp: "व्यक्तिगत कर्जा",
        link: "product/professional-loan/personal-loan",
      },
      {
        name: "Housing Loan",
        nameNp: "आवास कर्जा",
        link: "product/professional-loan/housing-loan",
      },
      {
        name: "Hire Purchase Loan",
        nameNp: "हायर पर्चेज कर्जा",
        link: "product/professional-loan/hire-purchase-loan",
      },
      {
        name: "Loan Against Share",
        nameNp: "शेयर धितो कर्जा",
        link: "product/professional-loan/loan-against-share",
      },
      {
        name: "Loan Against Fixed Deposit",
        nameNp: "मुद्दती निक्षेप धितो कर्जा",
        link: "product/professional-loan/loan-against-fixed-deposit",
      },
      {
        name: "Easy Business Loan",
        nameNp: "सजिलो व्यापार कर्जा",
        link: "product/professional-loan/easy-business-loan",
      },
    ],
  },
  {
    id: 3,
    name: "Services",
    slug: "services",
    nameNp: "सेवाहरू",
    img: "",
    subNavData: [
      {
        name: "Remittance",
        nameNp: "रेमिट्यान्स",
        link: "services/remittance",
      },
      {
        name: "ICFC Debit Card",
        nameNp: "ICFC डेबिट कार्ड",
        link: "services/stc-upi-debit-card",
      },
      {
        name: "ICFC Safe Locker Facility",
        nameNp: "ICFC सुरक्षित लकर सुविधा",
        link: "services/locker-facility",
      },
      {
        name: "Utility Bill Payment",
        nameNp: "उपयोगिता बिल भुक्तानी",
        link: "services/utility-bill-payment",
      },
      {
        name: "DEMAT",
        nameNp: "DEMAT",
        link: "services/demat",
      },
      {
        name: "ICFC eBanking",
        nameNp: "ICFC eBanking",
        link: "services/icfc-ebanking",
      },
      {
        name: "C-ASBA",
        nameNp: "C-ASBA",
        link: "services/casba",
      },
      {
        name: "Inter Bank Transaction",
        nameNp: "अन्तर बैंक लेनदेन",
        link: "services/inter-bank-transaction",
      },
      {
        name: "Load Digital Wallet",
        nameNp: "डिजिटल वालेट लोड गर्नुहोस्",
        link: "services/load-digital-wallet",
      },
      {
        name: "Discount Outlets",
        nameNp: "छुट आउटलेटहरू",
        link: "services/discount-outlets",
      },
      {
        name: "ICFC Finance Smart & SMS Banking",
        nameNp: "ICFC वित्त स्मार्ट र एसएमएस बैंकिङ",
        link: "services/mobile-banking",
      },
    ],
  },
  {
    id: 4,
    name: "Investor Relation",
    slug: "investor-relation",
    nameNp: "लगानीकर्ता सम्बन्ध",
    img: "",
    subNavData: [
      {
        name: "Capital Structure",
        nameNp: "पूंजी संरचना",
        link: "investor/capital_structure",
      },
      {
        name: "Base Rate",
        nameNp: "आधार दर",
        link: "investor/base_rate",
      },
      {
        name: "Interest Spread Rate",
        nameNp: "ब्याज प्रसार दर",
        link: "investor/spread_rates",
      },
      {
        name: "Monthly Interest Rates",
        nameNp: "मासिक ब्याज दर",
        link: "/interest-rates",
      },
      {
        name: "Yearly Interest Rates",
        nameNp: "वार्षिक ब्याज दर",
        link: "/fiscal-year-interest-rates",
      },
      {
        name: "Cost of Fund",
        nameNp: "कोषको लागत",
        link: "/investor/cost_of_fund",
      },
      {
        name: "AGM Minute",
        nameNp: "AGM मिनेट",
        link: "investor-relation/agm-minute",
      },
      {
        name: "Annual Reports",
        nameNp: "वार्षिक रिपोर्टहरू",
        link: "investor-relation/annual-report",
      },
      {
        name: "Financial Highlights",
        nameNp: "वित्तीय हाइलाइटहरू",
        link: "investor-relation/financial-report",
      },
      {
        name: "Basel Disclosure",
        nameNp: "बेसल प्रकटीकरण",
        link: "investor-relation/basel-report",
      },
      {
        name: "Key Indicators",
        nameNp: "प्रमुख संकेतकहरू",
        link: "investor-relation/key-indicators",
      },
      {
        name: "Corporate Governance Report",
        nameNp: "कर्पोरेट गभर्नेन्स रिपोर्ट",
        link: "investor-relation/corporate-governance",
      },
      {
        name: "Sebon Yearly Report",
        nameNp: "Sebon वार्षिक रिपोर्ट",
        link: "investor-relation/sebon-yearly-report",
      },
      {
        name: "Sebon Quarterly Report",
        nameNp: "Sebon त्रैमासिक रिपोर्ट",
        link: "investor-relation/sebon-quarterly-report",
      },
      {
        name: "Letter to Sebon",
        nameNp: "सेबोनलाई पत्र",
        link: "investor-relation/letter-to-sebon",
      },
    ],
  },
  {
    id: 5,
    name: "Our Networks",
    slug: "our-networks",
    nameNp: "हाम्रो नेटवर्कहरू",
    img: "",
    subNavData: [
      {
        name: "Branches",
        nameNp: "शाखाहरू",
        link: "/branch-location",
      },
      {
        name: "ATM Locations",
        nameNp: "एटीएम स्थानहरु",
        link: "/atm-location",
      },
      {
        name: "Member Networks",
        nameNp: "सदस्य नेटवर्कहरू",
        link: "/member-network",
      },
    ],
  },
  {
    id: 6,
    slug: "grievance",
    name: "Grievance",
    nameNp: "गुनासो",
    subNavData: [
      {
        name: "ICFC Grievance",
        nameNp: "ICFC गुनासो",
        link: "/grievance-form",
      },
      {
        name: "NRB Grievance Portal",
        nameNp: "NRB गुनासो पोर्टल",
        link: "",
        externalLink: "https://gunaso.nrb.org.np/",
      },
    ],
  },
];

const Navbar = () => {
  const [expanded, setExpanded] = React.useState("0");
  const [formatDate, setFormatDate] = useState(null);

  const d1 = new NepaliDate().format("MMMM D, YYYY");
  const dNepali = new NepaliDate().format("mmmm d, yyyy");
  // const d4 = format(new Date(), 'eeee')
  const d4 = new NepaliDate().format("DDDD");
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const d = new Date();
  const dayName = days[d.getDay()];
  const endate = d1 + "  (" + dayName + " ) ";
  // console.log(dayName);
  // console.log("date =>", typeof d1, d1);

  // const d2 = Intl.DateTimeFormat().resolvedOptions().timeZone === 'Asia/Katmandu';
  // console.log("d2 =>", typeof d2, d2);
  // console.log(new Date(d2).toISOString().slice(11, 19));

  const d2 = new Date();
  // console.log("d2 =>", typeof d2, d2);
  const d3 = formatInTimeZone(d2, "Asia/Katmandu", "HH:mm");
  // console.log("d3 =>", typeof d3, d3);

  const d5 = format(new Date(), "MMMM dd, yyyy");

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    // console.log("time =>", time.join (''))
    // setFormatDate(time.join (''))
    return time.join(""); // return adjusted time or original string
  }
  // if(d3){fs
  //     tConvert (d3);
  // }

  const navigate = useNavigate();
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const listRef = useRef();
  const transitionRef = useRef(null);
  // const navTransitionRef = useRef(null)
  // const [height, setHeight] = useState();

  const [isFix, setIsFix] = useState(false);
  const [isNav, setIsNav] = useState(false);
  const [isLoanNav, setIsLoanNav] = useState(false);
  const [isServicesNav, setIsServicesNav] = useState(false);
  const [isInvestorNav, setIsInvestorNav] = useState(false);
  const [isNetworkNav, setIsNetworkNav] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isAudioOpen, setIsAudioOpen] = useState(false);
  const [isNoticeOpen, setIsNoticeOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isLoginOpens, setIsLoginOpens] = useState(false);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [outsideClick, setOutsideClick] = useState(false);

  // console.log("activeMenu =>", activeMenu)
  // console.log("outsideClick =>", outsideClick)

  // const [isMounted, setIsMounted] = useState(false);

  const handleChange = (event) => {
    // setLanguage(event.target.value);
  };

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onClickNP = () => {
    lang.dispatch({ type: "NEPALI" });
    // console.log("nepali")
  };

  const onClickEN = () => {
    lang.dispatch({ type: "ENGLISH" });
    // console.log("english")
  };

  // This function calculates width and height of the list
  // const getListSize = () => {
  //     const newHeight = listRef.current.clientHeight || '350';
  //     setHeight(newHeight);
  // };

  // Get 'width' and 'height' after the initial render and every time the list changes
  // useEffect(() => {
  //     getListSize();
  // }, [isFix]);

  // Update 'width' and 'height' when the window resizes
  // useEffect(() => {
  //     window.addEventListener("resize", getListSize);
  // }, [isFix]);

  // function setFixed (){
  //     if(window.scrollY > 1 ) {
  //         setIsFix(true)
  //     } else {
  //         setIsFix(false)
  //     }
  // }

  // window.addEventListener("scroll", setFixed);

  // console.log("isFix =>", typeof isFix, isFix);
  // console.log("height =>", height);

  // const childNavToggle= (d) => {
  //     setIsNav(d);
  // }

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["siteData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/site_settings`)
        .then((res) => res.data),
  });

  //   if (isLoading) return(
  //     <div></div>
  //   );

  if (error) return "An error has occurred: " + error.message;

  //   console.log("siteData =>", data);

  //   hour={data.banking_hour} audio={data.autios}

  const GrievanceSubMenu = () => {
    const lang = React.useContext(LangContext);
    const np = lang.state.np;
    const found = NavData?.find((obj) => {
      return obj.slug === "grievance";
    });
    return (
      <ul className="nav__submenu">
        <hr className="DeskTopSubNavBarTopHR" />
        {found?.subNavData.map((value, index) => (
          <li key={index} className="nav__submenu-item ">
            {value.externalLink ? (
              <a href={value.externalLink} target="_blank">
                {np ? `${value.nameNp}` : `${value.name}`}
              </a>
            ) : (
              <Link to={value.link}>
                {np ? `${value.nameNp}` : `${value.name}`}
              </Link>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      {/* <div className='mainNavContainer' ref={listRef}> */}

      <div className="upNavBg">
        <div className="upNavContainer">
          <div className="upNavTextGroup">
            <p onClick={() => navigate("/career")}>
              {nep ? "आवश्यकता" : "Career"}
            </p>
            <p onClick={() => navigate("/downloads")}>
              {nep ? "डाउनलोडहरू" : "Downloads"}
            </p>
            <p onClick={() => navigate("/calendar")}>
              {nep ? "पात्रो" : "Calendar"}
            </p>
            <p className="grivance_main">
              {nep ? "गुनासो" : "Grievance"}

              <GrievanceSubMenu />
            </p>
          </div>

          <div className="upNavDateGroup">
            <BsCalendarWeek className="icon" size={20} />
            <p className="pr">{tConvert(d3)}</p>
            <p>{nep ? dNepali : endate}</p>
          </div>

          <div className="upNavExtraContainer">
            <ClickAwayListener onClickAway={() => setIsAudioOpen(false)}>
              <div className="dsnone" sx={{ position: "relative" }}>
                <IconButton
                  onClick={() => setIsAudioOpen((prev) => !prev)}
                  color="light"
                  aria-label="audio"
                >
                  <Badge
                    variant="dot"
                    color="error"
                    overlap="circular"
                    badgeContent=" "
                  >
                    <BiVolumeFull color="#fff" />
                  </Badge>
                </IconButton>

                {isAudioOpen ? (
                  <div className="audioDropContainer dsnone">
                    {isLoading || isFetching ? (
                      ""
                    ) : (
                      <AudioNoticeDrop audio={data?.autios} />
                    )}
                  </div>
                ) : null}
              </div>
            </ClickAwayListener>

            <ClickAwayListener onClickAway={() => setIsNoticeOpen(false)}>
              <div className="dsnone" sx={{ position: "relative" }}>
                <IconButton
                  onClick={() => setIsNoticeOpen((prev) => !prev)}
                  color="light"
                  aria-label="audio"
                >
                  <Badge
                    variant="dot"
                    color="error"
                    overlap="circular"
                    badgeContent=" "
                  >
                    <MdOutlineNotificationsNone color="#fff" />
                  </Badge>
                </IconButton>

                {isNoticeOpen ? (
                  <div className="noticeDropContainer dsnone">
                    {/* {isLoading || isFetching ?(''):(
                        <AudioNoticeDrop audio={data?.autios} />
                    )}   */}
                    <Notification
                      data={data?.notice}
                      stateChanger={setIsNoticeOpen}
                    />
                  </div>
                ) : null}
              </div>
            </ClickAwayListener>

            <IconButton className="dsnone" onClick={() => navigate("/search")}>
              <BiSearch color="#fff" />
            </IconButton>
            <Select
              sx={{
                p: 0,
                color: "#fff",
                fontFamily: "DM Sans, sans-serif",
              }}
              className="TopAltNavItemEnglishButton dsnone"
              defaultValue={Number(1)}
              inputprops={{
                "aria-label": "Without label",
                disableUnderline: true,
              }}
              onChange={handleChange}
            >
              <MenuItem
                value={Number(1)}
                className="NavbarLanguageMenuItem dsnone"
                onClick={() => {
                  onClickEN();
                }}
              >
                En
              </MenuItem>
              <MenuItem
                value={Number(2)}
                className="NavbarLanguageMenuItem dsnone"
                onClick={() => {
                  onClickNP();
                }}
              >
                Np
              </MenuItem>
            </Select>

            <div className="upNavBtnGroup">
              <ClickAwayListener onClickAway={() => setIsLoginOpen(false)}>
                <div sx={{ position: "relative" }}>
                  <div
                    onClick={() => setIsLoginOpen((prev) => !prev)}
                    className="upNavBtnLogin"
                  >
                    <img src={login} alt="login-icon" />
                    <h3>{nep ? "लग - इन" : "Login"}</h3>
                  </div>

                  {isLoginOpen && (
                    <div className="loginDropContainer">
                      <LoginDropdown />
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            </div>
            <div className="upNavBtnGroup">
              <ClickAwayListener onClickAway={() => setIsLoginOpens(false)}>
                <div sx={{ position: "relative" }}>
                  <div
                    onClick={() => setIsLoginOpens((prev) => !prev)}
                    className="upNavBtnLogin Onlineservicesss"
                  >
                    <h3>{nep ? "अनलाइन सेवाहरू" : "Online Applications"}</h3>
                  </div>

                  {isLoginOpens && (
                    <div className="loginDropContainer">
                      <OnlineDropdown />
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      </div>

      <div className="bottomNavBg">
        <div className="bottomNavContainer">
          <img
            src={logo}
            alt="logo"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
          <img src={mustcote} alt="mustcote" />
          {isLoading || isFetching ? (
            ""
          ) : (
            <div className="upNavBankingHour">
              {data.banking_hour.condition ? (
                <>
                  <Chip
                    sx={{ backgroundColor: "#008000", color: "#fff" }}
                    label="Open"
                  />
                  <p style={{ color: "#008000" }}>
                    Closes at {tConvert(data.banking_hour.closing_time)}
                  </p>
                </>
              ) : (
                <>
                  <Chip
                    sx={{ backgroundColor: "#dd1134", color: "#fff" }}
                    label="Closed"
                  />
                  <p style={{ color: "#dd1134" }}>
                    Opens at {tConvert(data.banking_hour.opening_time)}
                  </p>
                </>
              )}
            </div>
          )}

          <div className="bottomNavTextContainer">
            <OutsideClickHandler
              onOutsideClick={() => {
                setIsNav(false);
              }}
            >
              <div
                className=""
                onClick={() => {
                  setIsNav(!isNav);
                }}
              >
                <p style={{ color: `${isNav ? "#003399" : "#0f2137"}` }}>
                  {nep ? "हाम्रो बारे" : "About Us"}
                </p>

                <IoIosArrowDown
                  size="16px"
                  color={`${isNav ? "#003399" : "#0f2137"}`}
                  style={{
                    transform: `${isNav ? "rotate(180deg)" : "rotate(0deg)"}`,
                  }}
                  className="lg-nav-arrow"
                />
              </div>

              <MegaMenu
                isNav={isNav}
                transitionRef={transitionRef}
                height={70}
                childNavToggle={() => setIsNav(false)}
              />
            </OutsideClickHandler>

            <OutsideClickHandler
              onOutsideClick={() => {
                setIsLoanNav(false);
              }}
            >
              <div onClick={() => setIsLoanNav(!isLoanNav)}>
                <p style={{ color: `${isLoanNav ? "#003399" : "#0f2137"}` }}>
                  {nep ? "हाम्रा सेवाहरू" : "Our Products"}
                </p>
                <IoIosArrowDown
                  size="16px"
                  color={`${isLoanNav ? "#003399" : "#0f2137"}`}
                  style={{
                    transform: `${
                      isLoanNav ? "rotate(180deg)" : "rotate(0deg)"
                    }`,
                  }}
                  className="lg-nav-arrow"
                />
              </div>

              <MegaMenuLoans
                isNav={isLoanNav}
                transitionRef={transitionRef}
                height={70}
                childNavToggle={() => setIsLoanNav(false)}
              />
            </OutsideClickHandler>

            <OutsideClickHandler
              onOutsideClick={() => {
                setIsServicesNav(false);
              }}
            >
              <div onClick={() => setIsServicesNav(!isServicesNav)}>
                {/* <img src={invest} alt="invest" /> */}
                <p
                  style={{ color: `${isServicesNav ? "#003399" : "#0f2137"}` }}
                >
                  {nep ? "सेवाहरू" : "Services"}
                </p>
                <IoIosArrowDown
                  size="16px"
                  color={`${isServicesNav ? "#003399" : "#0f2137"}`}
                  style={{
                    transform: `${
                      isServicesNav ? "rotate(180deg)" : "rotate(0deg)"
                    }`,
                  }}
                  className="lg-nav-arrow"
                />
              </div>

              <MegaMenuServices
                isNav={isServicesNav}
                transitionRef={transitionRef}
                height={70}
                childNavToggle={() => setIsServicesNav(false)}
              />
            </OutsideClickHandler>

            <OutsideClickHandler
              onOutsideClick={() => {
                setIsInvestorNav(false);
              }}
            >
              <div onClick={() => setIsInvestorNav(!isInvestorNav)}>
                {/* <img src={service} alt="service" /> */}
                <p
                  style={{ color: `${isInvestorNav ? "#003399" : "#0f2137"}` }}
                >
                  {nep ? "लगानीकर्तासँगको सम्बन्ध" : "Investor Relation"}
                </p>

                <IoIosArrowDown
                  size="16px"
                  color={`${isInvestorNav ? "#003399" : "#0f2137"}`}
                  style={{
                    transform: `${
                      isInvestorNav ? "rotate(180deg)" : "rotate(0deg)"
                    }`,
                  }}
                  className="lg-nav-arrow"
                />
              </div>

              <MegaMenuInvestor
                isNav={isInvestorNav}
                transitionRef={transitionRef}
                height={70}
                childNavToggle={() => setIsInvestorNav(false)}
              />
            </OutsideClickHandler>

            <OutsideClickHandler
              onOutsideClick={() => {
                setIsNetworkNav(false);
              }}
            >
              <div onClick={() => setIsNetworkNav(!isNetworkNav)}>
                {/* <img src={network} alt="network" /> */}
                <p style={{ color: `${isNetworkNav ? "#003399" : "#0f2137"}` }}>
                  {nep ? "हाम्रो संजाल" : "Our Network"}
                </p>
                <IoIosArrowDown
                  size="16px"
                  color={`${isNetworkNav ? "#003399" : "#0f2137"}`}
                  style={{
                    transform: `${
                      isNetworkNav ? "rotate(180deg)" : "rotate(0deg)"
                    }`,
                  }}
                  className="lg-nav-arrow"
                />
              </div>
              <MegaMenuNetwork
                isNav={isNetworkNav}
                transitionRef={transitionRef}
                height={70}
                childNavToggle={() => setIsNetworkNav(false)}
              />
            </OutsideClickHandler>
            <div>
              <Link className="BankingHours" to="/banking-hours">
                {nep ? "बैंकिङ समय" : "Banking Hours"}
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}

      {/* <OutsideClickHandler
      onOutsideClick={() => {
        setIsNav(false);
      }}
    >


    </OutsideClickHandler> */}

      <div className="MobileNavBarContainer">
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className="MobileDrawerContainer"
        >
          <img
            onClick={() => {
              navigate("/");
              setIsOpen(false);
            }}
            src={logoFull}
            alt="logo-full"
          />

          <div className="mobile-menu-container">
            {NavData.map((i, index) => (
              <Accordion
                key={index}
                expanded={expanded === `${index}`}
                onChange={handleAccordionChange(`${index}`)}
                sx={{
                  "& .MuiPaper-root-MuiAccordion-root.Mui-expanded": {
                    margin: "0px",
                  },
                  "& .MuiPaper-root-MuiAccordion-root": {
                    border: "2px solid red",
                    borderRadius: "10px",
                  },
                  "& .MuiAccordionSummary-root": {
                    height: "20px",
                    backgroundColor: "#fff",
                    color: "#003399",
                    borderRadius: "10px",
                  },
                  "& .MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "30px",
                    "& .MuiTypography-root": {
                      color: "#dd1134",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#dd1134",
                    },
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "20px",
                      color: "#003399",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {nep ? i.nameNp : i.name}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <div className="mobile-nav-accordion-details-container">
                    {i.subNavData.map((item, index) => (
                      <Link
                        key={index}
                        to={item.link}
                        onClick={() => setIsOpen(false)}
                      >
                        {nep ? item.nameNp : item.name}
                      </Link>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}

            <div className="singleLink">
              <p
                onClick={() => {
                  navigate("/downloads");
                  setIsOpen(false);
                }}
              >
                {nep ? "डाउनलोडहरू" : "Downloads"}
              </p>
            </div>
            <div className="singleLink">
              <p
                onClick={() => {
                  navigate("/career");
                  setIsOpen(false);
                }}
              >
                {nep ? "क्यारियर" : "Career"}
              </p>
            </div>
            <div className="singleLink">
              <p
                onClick={() => {
                  navigate("/calendar");
                  setIsOpen(false);
                }}
              >
                {nep ? "पात्रो" : "Calendar"}
              </p>
            </div>
            <div className="singleLink">
              <p
                onClick={() => {
                  navigate("/banking-hours");
                  setIsOpen(false);
                }}
              >
                {nep ? "बैंकिङ समय" : "Banking Hours"}
              </p>
            </div>

            <div className="drawerLangContainer">
              <p>Language :</p>
              <Select
                sx={{
                  p: 0,
                  color: "#000",
                  fontFamily: "DM Sans, sans-serif",
                }}
                className="TopAltNavItemEnglishButton"
                defaultValue={Number(1)}
                inputprops={{
                  "aria-label": "Without label",
                  disableUnderline: true,
                }}
                onChange={handleChange}
              >
                <MenuItem
                  value={Number(1)}
                  className="NavbarLanguageMenuItem"
                  onClick={() => {
                    onClickEN();
                  }}
                >
                  En
                </MenuItem>
                <MenuItem
                  value={Number(2)}
                  className="NavbarLanguageMenuItem"
                  onClick={() => {
                    onClickNP();
                  }}
                >
                  {/* नेपाली */}
                  Np
                </MenuItem>
              </Select>
            </div>
          </div>
        </Drawer>

        <div className="mobileNavImageContainer">
          {/* <a href="#"> */}
          <img
            onClick={() => navigate("/")}
            className="mobileNavLogoImage"
            src={logo}
            alt="logo"
          />
          {/* </a> */}

          {isLoading ? (
            ""
          ) : (
            <div className="upNavBankingHour">
              {data.banking_hour.condition ? (
                <>
                  <Chip
                    sx={{ backgroundColor: "#008000", color: "#fff" }}
                    label="Open at"
                  />
                  <p style={{ color: "#008000" }}>
                    Closes at {tConvert(data.banking_hour.closing_time)}
                  </p>
                </>
              ) : (
                <>
                  <Chip
                    sx={{ backgroundColor: "#dd1134", color: "#fff" }}
                    label="Closed at"
                  />
                  <p style={{ color: "#dd1134" }}>
                    Opens at {tConvert(data.banking_hour.opening_time)}{" "}
                  </p>
                </>
              )}
            </div>
          )}

          <GiHamburgerMenu onClick={toggleDrawer} size="24px" color="3a3a3a" />
        </div>
      </div>
    </>
  );
};

const MegaMenu = ({ isNav, transitionRef, height, childNavToggle }) => {
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;

  return (
    <CSSTransition
      in={isNav}
      transitionRef={transitionRef}
      timeout={1000}
      unmountOnExit
      classNames="mega-menu-transition-class"
    >
      <div
        ref={transitionRef}
        dismissible
        style={{ top: `${height}px` }}
        className="mega-nav-container"
      >
        <div className="container">
          <div className="content-box">
            <h4>{nep ? "हाम्रो बारे" : "ABOUT US"}</h4>

            <Link
              to="about-us/company-profile"
              onClick={() => childNavToggle()}
            >
              {nep ? "कम्पनी प्रोफइल" : "Company Profile"}
            </Link>
            <Link to="about-us/ceo-message" onClick={() => childNavToggle()}>
              {nep ? "प्रमुख कार्यकारी अधिकृतको सन्देश" : "CEO's Message"}
            </Link>
            <Link to="teams/bod" onClick={() => childNavToggle()}>
              {nep ? "सञ्चालक समिति" : "Board of Director"}
            </Link>
            <Link to="teams/management-team" onClick={() => childNavToggle()}>
              {nep ? "व्यवस्थापन समूह" : "Management Team"}
            </Link>
            <Link to="teams/hod" onClick={() => childNavToggle()}>
              {nep ? "विभागीय प्रमुख" : "Head of Department"}
            </Link>
            <Link to="teams/regional-head" onClick={() => childNavToggle()}>
              {nep ? "प्रदेश प्रमुखहरु" : "Regional Head"}
            </Link>
            <Link to="teams/company-secretary" onClick={() => childNavToggle()}>
              {nep ? "कम्पनी सचिव" : "Company Secretary"}
            </Link>
            <Link
              to="officers/officer/amit-shrestha"
              onClick={() => childNavToggle()}
            >
              {nep ? "गुनासो अधिकारी" : "Grievance Officer"}
            </Link>
            <Link
              to="officers/officer/sanjaya-thapaliya1716534771"
              onClick={() => childNavToggle()}
            >
              {nep ? "सूचना अधिकारी" : "Information Officer"}
            </Link>
            <Link
              to="officers/officer/suraj-timalsina1716706768"
              onClick={() => childNavToggle()}
            >
              {nep ? "गुनासो अधिकारी" : "Compliance Officer"}
            </Link>
            <Link
              to="branch-officers/information"
              onClick={() => childNavToggle()}
            >
              {nep
                ? "शाखा अनुसार गुनासो र सूचना अधिकारीहरू"
                : "Branchwise Grievance and Information Official"}
            </Link>
            {/* <Link to="teams/it-officers" onClick={() => childNavToggle()}>
              {nep ? "सूचना अधिकारीहरू" : "IT Officials"}
            </Link> */}
            <Link to="/csr" onClick={() => childNavToggle()}>
              {nep ? "सामाजिक उत्तरदायित्व" : "Commitment to CSR"}
            </Link>
            <Link to="/achievements" onClick={() => childNavToggle()}>
              {nep ? "उपलब्धि र माइलस्टोनहरू" : "Achievements and Milestones"}
            </Link>
            <Link to="/notices" onClick={() => childNavToggle()}>
              {nep ? "सूचनाहरू" : "Notices"}
            </Link>
            <Link to="/news" onClick={() => childNavToggle()}>
              {nep ? "समाचार" : "News"}
            </Link>
          </div>

          <img className="megaImage" src={img06} alt="img-06" />
        </div>
      </div>
      {/* ):(<></>)} */}
    </CSSTransition>
  );
};

const MegaMenuLoans = ({ isNav, transitionRef, height, childNavToggle }) => {
  const navigate = useNavigate();
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;

  return (
    <CSSTransition
      // in={activeMenu !== '0'}
      in={isNav}
      transitionRef={transitionRef}
      timeout={1000}
      unmountOnExit
      classNames="mega-menu-transition-class"
    >
      {/* {isNav?( */}
      <div
        ref={transitionRef}
        dismissible
        className="mega-nav-container"
        style={{ top: `${height}px` }}
      >
        <div className="container">
          <div className="content-box">
            <h4
              onClick={() => {
                navigate("product/saving-account/icfc-bumper-saving-account");
                childNavToggle();
              }}
            >
              {nep ? "बचत खाता" : "Saving Account"}
            </h4>

            <Link
              to="product/saving-account/icfc-bumper-saving-account"
              onClick={() => childNavToggle()}
            >
              {nep ? "ICFC बम्पर बचत खाता" : "ICFC Bumper Saving Account"}
            </Link>
            <Link
              to="product/saving-account/icfc-social-security"
              onClick={() => childNavToggle()}
            >
              {nep ? "ICFC सामाजिक सुरक्षा" : "ICFC Social Security"}
            </Link>
            {/* <Link
              to="product/saving-account/icfc-premium-saving"
              onClick={() => childNavToggle()}
            >
              {nep ? "ICFC प्रिमियम बचत" : "ICFC Premium Saving"}
            </Link> */}
            {/* <Link
              to="product/saving-account/saving-account"
              onClick={() => childNavToggle()}
            >
              {nep ? "बचत खाता" : "Saving Account"}
            </Link> */}
            <Link
              to="product/saving-account/employee-account"
              onClick={() => childNavToggle()}
            >
              {nep ? "कर्मचारी खाता" : "Employee Account"}
            </Link>
            <Link
              to="product/saving-account/special-saving"
              onClick={() => childNavToggle()}
            >
              {nep ? "विशेष बचत" : "Special Saving"}
            </Link>
            {/* <Link
              to="product/saving-account/super-saving"
              onClick={() => childNavToggle()}
            >
              {nep ? "सुपर बचत खाता" : "Super Saving Account"}
            </Link> */}
            <Link
              to="product/saving-account/icfc-zero-saving"
              onClick={() => childNavToggle()}
            >
              {nep ? "ICFC शून्य ब्यालेन्स" : "ICFC Zero Balance"}
            </Link>
            <Link
              to="product/saving-account/senior-citizen-saving"
              onClick={() => childNavToggle()}
            >
              {nep ? "वरिष्ठ नागरिक खाता" : "Senior Citizen A/C"}
            </Link>
            <Link
              to="product/saving-account/ketaketi-bachat"
              onClick={() => childNavToggle()}
            >
              {nep ? "केटा केटी बचत" : "Keta Keti Bachat"}
            </Link>
            <Link
              to="product/saving-account/nari-bachat"
              onClick={() => childNavToggle()}
            >
              {nep ? "नारी बचत" : "Nari Bachat"}
            </Link>
            <Link
              to="product/saving-account/yuva-bachat"
              onClick={() => childNavToggle()}
            >
              {nep ? "युवा बचत" : "Yuva Bachat"}
            </Link>
            {/* <Link
              to="product/saving-account/golden-saving"
              onClick={() => childNavToggle()}
            >
              {nep ? "गोल्डेन बचत" : "Golden Saving"}
            </Link> */}
            <Link
              to="product/saving-account/jumbo-saving"
              onClick={() => childNavToggle()}
            >
              {nep ? "जम्बो बचत" : "Jumbo Saving"}
            </Link>
            <Link
              to="product/saving-account/asba-saving-account"
              onClick={() => childNavToggle()}
            >
              {nep ? "ASBA बचत" : "ASBA Saving"}
            </Link>
            <Link
              to="product/saving-account/remittance-saving-account"
              onClick={() => childNavToggle()}
            >
              {nep ? "रेमिट्यान्स बचत खाता" : "Remittance Saving Account"}
            </Link>

            <h4
              onClick={() => {
                navigate("product/fixed-deposit/recurring-fixed-deposit");
                childNavToggle();
              }}
            >
              {nep ? "फिक्स्ड डिपोजिट" : "Fixed Deposit"}
            </h4>
            <Link
              to="product/fixed-deposit/recurring-fixed-deposit"
              onClick={() => childNavToggle()}
            >
              {nep ? "आवर्ती फिक्स्ड डिपोजिट" : "Recurring Fixed Deposit"}
            </Link>
            <Link
              to="product/fixed-deposit/fixed-deposit"
              onClick={() => childNavToggle()}
            >
              {nep ? "फिक्स्ड डिपोजिट" : "Fixed Deposit"}
            </Link>

            <h4
              onClick={() => {
                navigate("product/professional-loan/sme-loan");
                childNavToggle();
              }}
            >
              {nep ? "कर्जा" : "Loan Products"}
            </h4>
            <Link
              to="product/professional-loan/sme-loan"
              onClick={() => childNavToggle()}
            >
              {nep ? "SME कर्जा" : "SME Loan"}
            </Link>
            <Link
              to="product/professional-loan/personal-loan"
              onClick={() => childNavToggle()}
            >
              {nep ? "व्यक्तिगत कर्जा" : "Personal Loan"}
            </Link>
            <Link
              to="product/professional-loan/housing-loan"
              onClick={() => childNavToggle()}
            >
              {nep ? "आवास कर्जा" : "Housing Loan"}
            </Link>
            <Link
              to="product/professional-loan/hire-purchase-loan"
              onClick={() => childNavToggle()}
            >
              {nep ? "हायर पर्चेज कर्जा" : "Hire Purchase Loan"}
            </Link>
            <Link
              to="product/professional-loan/loan-against-share"
              onClick={() => childNavToggle()}
            >
              {nep ? "शेयर धितो कर्जा" : "Loan Against Share"}
            </Link>
            <Link
              to="product/professional-loan/loan-against-fixed-deposit"
              onClick={() => childNavToggle()}
            >
              {nep
                ? "मुद्दती निक्षेप धितो कर्जा"
                : "Loan Against Fixed Deposit"}
            </Link>
            <Link
              to="product/professional-loan/easy-business-loan"
              onClick={() => childNavToggle()}
            >
              {nep ? "सजिलो व्यापार कर्जा" : "Easy Business Loan"}
            </Link>
          </div>

          {/* <img className="megaImage" src={img02} alt="img-02" /> */}
        </div>
      </div>
    </CSSTransition>
  );
};

const MegaMenuServices = ({ isNav, transitionRef, height, childNavToggle }) => {
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;
  return (
    <CSSTransition
      // in={activeMenu !== '0'}
      in={isNav}
      transitionRef={transitionRef}
      timeout={1000}
      unmountOnExit
      classNames="mega-menu-transition-class"
    >
      <div
        ref={transitionRef}
        dismissible
        style={{ top: `${height}px` }}
        className="mega-nav-container"
      >
        <div className="container">
          <div className="content-box">
            <h4>{nep ? "सेवाहरू" : "Services"}</h4>

            <Link to="services/remittance" onClick={() => childNavToggle()}>
              {nep ? "रेमिट्यान्स" : "Remittance"}
            </Link>
            <Link
              to="services/stc-upi-debit-card"
              onClick={() => childNavToggle()}
            >
              {nep ? "ICFC डेबिट कार्ड" : "ICFC Debit Card"}
            </Link>
            <Link
              to="services/locker-facility"
              onClick={() => childNavToggle()}
            >
              {nep ? "ICFC सुरक्षित लकर सुविधा" : "ICFC Safe Locker Facility"}
            </Link>
            <Link
              to="services/utility-bill-payment"
              onClick={() => childNavToggle()}
            >
              {nep ? "उपयोगिता बिल भुक्तानी" : "Utility Bill Payment"}
            </Link>
            <Link to="services/demat" onClick={() => childNavToggle()}>
              {nep ? "DEMAT" : "DEMAT"}
            </Link>
            <Link to="services/icfc-ebanking" onClick={() => childNavToggle()}>
              {nep ? "ICFC eBanking" : "ICFC eBanking"}
            </Link>
            <Link to="services/casba" onClick={() => childNavToggle()}>
              {nep ? "C-ASBA" : "C-ASBA"}
            </Link>
            <Link
              to="services/inter-bank-transaction"
              onClick={() => childNavToggle()}
            >
              {nep ? "अन्तर बैंक लेनदेन" : "Inter Bank Transaction"}
            </Link>
            <Link
              to="services/load-digital-wallet"
              onClick={() => childNavToggle()}
            >
              {nep ? "डिजिटल वालेट लोड गर्नुहोस्" : "Load Digital Wallet"}
            </Link>
            <Link
              to="services/discount-outlets"
              onClick={() => childNavToggle()}
            >
              {nep ? "छुट आउटलेटहरू" : "Discount Outlets"}
            </Link>
            <Link to="services/mobile-banking" onClick={() => childNavToggle()}>
              {nep
                ? "ICFC वित्त स्मार्ट र एसएमएस बैंकिङ"
                : "ICFC Finance Smart & SMS Banking"}
            </Link>
            <Link to="services/mobile-banking" onClick={() => childNavToggle()}>
              {nep ? "मोबाइल बैंकिङ" : "Mobile Banking"}
            </Link>
          </div>

          <img className="megaImage" src={img03} alt="img-03" />
        </div>
      </div>
    </CSSTransition>
  );
};

const MegaMenuInvestor = ({ isNav, transitionRef, height, childNavToggle }) => {
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;

  return (
    <CSSTransition
      // in={activeMenu !== '0'}
      in={isNav}
      transitionRef={transitionRef}
      timeout={1000}
      unmountOnExit
      classNames="mega-menu-transition-class"
    >
      {/* {isNav?( */}

      <div
        ref={transitionRef}
        dismissible
        className="mega-nav-container"
        style={{ top: `${height}px` }}
      >
        <div className="container">
          <div className="content-box">
            <h4>{nep ? "लगानीकर्ता सम्बन्ध" : "Investor Relation"}</h4>
            <Link
              to="investor/capital_structure"
              onClick={() => childNavToggle()}
            >
              {nep ? "पूंजी संरचना" : "Capital Structure"}
            </Link>
            <Link to="investor/base_rates" onClick={() => childNavToggle()}>
              {nep ? "आधार दर" : "Base Rate"}
            </Link>
            <Link to="investor/spread_rates" onClick={() => childNavToggle()}>
              {nep ? "ब्याज प्रसार दर" : "Interest Spread Rate"}
            </Link>
            <Link to="/interest-rates" onClick={() => childNavToggle()}>
              {nep ? "मासिक ब्याज दर" : "Monthly Interest Rates"}
            </Link>
            <Link
              to="/fiscal-year-interest-rates"
              onClick={() => childNavToggle()}
            >
              {nep ? "वार्षिक ब्याज दर" : "Yearly Interest Rates"}
            </Link>
            <Link to="/investor/cost_of_fund" onClick={() => childNavToggle()}>
              {nep ? "कोषको लागत" : "Cost of Fund"}
            </Link>
            {/* <Link to="investor-relation/" onClick={() => childNavToggle()}>Uncollected Dividend</Link> */}
            <h4>{nep ? "रिपोर्टहरू" : "Reports"}</h4>
            <Link
              to="investor-relation/agm-minute"
              onClick={() => childNavToggle()}
            >
              {nep ? "AGM मिनेट" : "AGM Minutes"}
            </Link>
            <Link
              to="investor-relation/annual-report"
              onClick={() => childNavToggle()}
            >
              {nep ? "वार्षिक रिपोर्टहरू" : "Annual Reports"}
            </Link>
            <Link
              to="investor-relation/financial-report"
              onClick={() => childNavToggle()}
            >
              {nep ? "वित्तीय हाइलाइटहरू" : "Financial Highlights"}
            </Link>
            <Link
              to="investor-relation/basel-report"
              onClick={() => childNavToggle()}
            >
              {nep ? "बेसल प्रकटीकरण" : "Basel Disclosure"}
            </Link>
            <Link
              to="investor-relation/key-indicators"
              onClick={() => childNavToggle()}
            >
              {nep ? "प्रमुख संकेतकहरू" : "Key Indicators"}
            </Link>
            <h4>Sebon Reports</h4>
            <Link
              to="investor-relation/corporate-governance"
              onClick={() => childNavToggle()}
            >
              {nep
                ? "कर्पोरेट गभर्नेन्स रिपोर्ट"
                : "Corporate Governance Report"}
            </Link>
            <Link
              to="investor-relation/sebon-yearly-report"
              onClick={() => childNavToggle()}
            >
              {nep ? "Sebon वार्षिक रिपोर्ट" : "Sebon Yearly Report"}
            </Link>
            <Link
              to="investor-relation/sebon-quarterly-report"
              onClick={() => childNavToggle()}
            >
              {nep ? "Sebon त्रैमासिक रिपोर्ट" : "Sebon Quarterly Report"}
            </Link>
            <Link
              to="investor-relation/letter-to-sebon"
              onClick={() => childNavToggle()}
            >
              {nep ? "सेबोनलाई पत्र" : "Letter to Sebon"}
            </Link>
            <Link to="teams/company-secretary" onClick={() => childNavToggle()}>
              <h4>{nep ? "कम्पनी सचिव" : "Company Secretary"}</h4>
            </Link>
          </div>

          {/* <img className="megaImage" src={img05} alt="img-05" /> */}
        </div>
      </div>
      {/* ):(<></>)} */}
    </CSSTransition>
  );
};

const MegaMenuNetwork = ({ isNav, transitionRef, height, childNavToggle }) => {
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;

  return (
    <CSSTransition
      // in={activeMenu !== '0'}
      in={isNav}
      transitionRef={transitionRef}
      timeout={1000}
      unmountOnExit
      classNames="mega-menu-transition-class"
    >
      {/* {isNav?( */}
      <div
        ref={transitionRef}
        dismissible
        className="mega-nav-container"
        style={{ top: `${height}px` }}
      >
        <div className="container">
          <div className="content-box">
            <h4>{nep ? "हाम्रो नेटवर्कहरू" : "Our Networks"}</h4>
            <Link to="/branch-location" onClick={() => childNavToggle()}>
              {nep ? "शाखाहरू" : "Branches"}
            </Link>
            <Link to="/atm-location" onClick={() => childNavToggle()}>
              {nep ? "एटीएम स्थानहरू" : "ATM Locations"}
            </Link>
            <Link to="/member-network" onClick={() => childNavToggle()}>
              {nep ? "सदस्य नेटवर्क" : "Member Network"}
            </Link>
          </div>
          {/* <img className="megaImage" src={img04} alt="img-04" /> */}
        </div>
      </div>
      {/* ):(<></>)} */}
    </CSSTransition>
  );
};

export default Navbar;
